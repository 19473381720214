<template>
  <div class="middle">
    <div class="mx-2 mx-md-5" v-if="$i18n.locale === 'en'">
      <h1 class="mt-3 fredoka text-center">Insurance and breakdown</h1>
      <p>
        We are able to offer you
        <b>additional insurance options</b> to cover your loss and reduce the excess fee. Please check the detail information of the insurance options below and select one if you need. For full term and conditions, please
        <span
          @click="goto('/terms')"
          class="hover-universal"
        >check here</span>.
      </p>
      <b-card no-body>
        <b-table
          responsive
          class="border-top mb-0"
          striped
          :hover="false"
          :fields="insuranceFields"
          :items="insuranceItems"
          thead-class="bg-light text-info text-center"
          tbody-tr-class="text-center"
        >
          <template v-slot:cell(item)="data">
            <span class="text-left d-block pl-sm-2">{{ data.value }}</span>
          </template>
          <template v-slot:head(item)>
            <span class="text-left d-block pl-sm-2">Insurance Comparison</span>
          </template>
        </b-table>
      </b-card>
      <p style="font-size:1rem">*The pre-authorisation bond will be released back to your card within 10-15 working days after return the vehicle.</p>

      <h5 class="mt-4">For mechanical issues</h5>
      <p>We will cover all mechanical breakdowns which include engine faults, electrical faults, cooling faults once diagnosed by AA mechanician.</p>
      <h5 class>For non-mechanical</h5>
      <p>
        All non-mechanical breakdown which includes flat tyre, flat battery, window screen broken, etc. will be paid by customer, including call-out fee once diagnosed by AA mechanician. It can be waived if you purchased
        <b>Full Cover Insurance</b>.
      </p>
    </div>
    <div class="mx-2 mx-md-5" v-else>
      <h1 class="mt-3 fredoka text-center">保险和道路救援</h1>
      <p>
        我们可以为您提供额外的保险选择，以弥补您的损失并减少额外的费用。请查看以下保险选项的详细信息，并根据需要选择一个。请查看此链接查看完整的条款和条件
        <span @click="goto('/terms')" class="hover-universal">点击</span>.
      </p>
      <b-card no-body>
        <b-table
          responsive
          class="border-top mb-0"
          striped
          :hover="false"
          :fields="$i18n.locale === 'en'?  insuranceFields: insuranceFields_zh"
          :items="$i18n.locale === 'en'? insuranceItems: insuranceItems_zh"
          thead-class="bg-light text-info text-center"
          tbody-tr-class="text-center"
        >
          <template v-slot:cell(item)="data">
            <span class="text-left d-block pl-sm-2">{{ data.value }}</span>
          </template>
          <template v-slot:head(item)>
            <span class="text-left d-block pl-sm-2">保险对比</span>
          </template>
        </b-table>
      </b-card>
      <p style="font-size:1rem">*预授权保证金将于车辆归还后10-15个工作日内退回到您的银行卡卡。</p>

      <h5 class="mt-4">对于机械问题</h5>
      <p>我们将承担所有机械故障，包括发动机故障，电气故障，冷却故障，需要由AA认证的机械师诊断。</p>
      <h5 class>对于非机械问题</h5>
      <p>所有非机械性故障，包括轮胎漏气、电池漏气、窗纱破裂等，由客户支付，包括AA机械师诊的救援费用。如果您购买了全险，则可以免除。</p>
    </div>
  </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Rental Car Insurance New Zealand| Top Rental",
        meta: [
            {
                name: "Keywords",
                content: "rental car insurance",
            },
            {
                name: "Description",
                content:
                    "We offer additional insurance options to cover your losses and reduce the excess fee. Our insurance cover includes both mechanical and non-mechanical issues. ",
            },
        ],
    },
    data() {
        return {
            insuranceFields: [
                { key: "item", label: "" },
                { key: "basic", label: "Basic Cover" },
                { key: "plus", label: "Limited Cover" },
                { key: "premium", label: "Full Cover" },
            ],
            insuranceFields_zh: [
                { key: "item", label: "" },
                { key: "basic", label: "基本版" },
                { key: "plus", label: "偷盗险" },
                { key: "premium", label: "全险" },
            ],
            insuranceItems: [
                {
                    item: "Insurance price",
                    basic: "$0 NZD/day",
                    plus: "$32 NZD/day",
                    premium: "$39 NZD/day",
                },
                {
                    item: "Rental Vehicle Damage Excess Fee",
                    basic: "$4,800 NZD",
                    plus: "$800 NZD",
                    premium: "$0 NZD",
                },

                {
                    item: "Pre-Authorization on hold (as bond)",
                    basic: "$500 NZD",
                    plus: "$300 NZD",
                    premium: "$100 NZD",
                },
                {
                    item: "Your Vehicle Theft Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "AA Roadside Assistance Callout Fee",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Window Screen & Glass Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Tyres Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
                {
                    item: "Third Party Vehicle /  Property Damage Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
            ],
            insuranceItems_zh: [
                {
                    item: "保险价格",
                    basic: "$0 NZD/天",
                    plus: "$32 NZD/天",
                    premium: "$39 NZD/天",
                },
                {
                    item: "租车损坏赔偿金",
                    basic: "$4,800 NZD",
                    plus: "$800 NZD",
                    premium: "$0 NZD",
                },

                {
                    item: "预授权保证金",
                    basic: "$500 NZD",
                    plus: "$300 NZD",
                    premium: "$100 NZD",
                },
                {
                    item: "车辆盗窃险",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "道路救援费",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "车窗玻璃险",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "轮胎保险",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
                {
                    item: "第三方车辆/财产损坏保险",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
            ],
        };
    },
    methods: {
        goto(route) {
            this.$router.push(route);
        },
    },
};
</script>

<style scoped>
.caption-insurance {
    background-color: azure;
}
p {
    font-size: 1.2rem;
}
</style>