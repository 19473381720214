<template>
  <div style="margin-bottom: 5rem;">
    <hr class="line" />
    <div class="hot-title">
      <h1 class="tt-font">Frequently Asked Questions</h1>
      <div>
        <p class="hot-words">
          This is where you will find important information you need to know when hiring a rental car with
          <span class="soft-blue">Top Rental</span>.
        </p>
      </div>
    </div>
    <div>
      <ul>
        <li>
          <b-row @click="list.expand1 = !list.expand1">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue h5" :class="list.expand1 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How do I book a rental car with Top Rental?</span>
            </span>
          </b-row>
          <div v-show="list.expand1" class="answer">
            The easiest way to book is through our website
            <a href="/home">www.toprental.co.nz</a>;
            <br />You can also email us at
            <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a> or give us a call on:
            <br />
            <ul>
              <li>
                <a href="tel:0800 668 628">0800 668 628</a> From New Zealand
              </li>
              <li>
                <a href="tel:1800 861 966">1800 861 966</a> From Australia
              </li>
            </ul>
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand2 = !list.expand2">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue h5" :class="list.expand2 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Are Top Rental’s prices all inclusive?</span>
            </span>
          </b-row>
          <div v-show="list.expand2" class="answer">
            All shown prices are in NZD (New Zealand Dollars) and include 15% GST (Good and Service Tax), basic standard insurance liability cover.
            <br />
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand3 = !list.expand3">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand3 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How can I pay for my rental car?</span>
            </span>
          </b-row>
          <div v-show="list.expand3" class="answer">
            You will be asked to pay rental fee at counter before picked up your car. We accept all main multiple payment method which included Eftpos, Credit Card (VISA/MasterCard), Alipay, WeChat Pay, NZ Online Banking.
            <br />
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand4 = !list.expand4">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand4 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do I have to pay a deposit/Bond when pick up?</span>
            </span>
          </b-row>
          <div v-show="list.expand4" class="answer">
            You will have to pay $800 NZD secure deposit when pick up the car. We will only hold as pre-authorization. Your deposit will release back in 10 working days after returned vehicle.
            <br />
            <br />
            <b>Note:</b> we only accept Visa or Mastercard under driver’s name for deposit.
            <br />
            <pre>Cash, Debit card, American Express card are Not Accept.</pre>
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand5 = !list.expand5">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand5 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I use some else’s credit card?</span>
            </span>
          </b-row>
          <div v-show="list.expand5" class="answer">For security reason, we only accept driver’s credit card.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand6 = !list.expand6">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand6 ? 'minus' : 'plus'"></i>
              <span>&nbsp; What is your cancellation policy?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand6"
            class="answer"
          >Top Rental offer free cancellation policy. To cancel your booking, Just click “Mange your booking” then enter your details to finish your cancellation.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand7 = !list.expand7">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand7 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I request a certain make/model?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand7"
            class="answer"
          >We do the best to keep the certain model you requested. But however, we can’t promise as the vehicle stock may change quiet often.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand8 = !list.expand8">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand8 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drive with my overseas licence?</span>
            </span>
          </b-row>
          <div v-show="list.expand8" class="answer">
            You need a valid overseas licence and a valid translation if not in English.
            <br />
            <br />
            <b>Note:</b> You must not be given a disqualification or suspension in New Zealand.
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand9 = !list.expand9">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand9 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Is there any age requirement for driver?</span>
            </span>
          </b-row>
          <div v-show="list.expand9" class="answer">
            You can drive our rental car if the age is over 21 years old. There is no maximum age to hire the car.
            <br />
            <br />
            <b>Note:</b>
            If the age is between 21 to 25 years old, it will has a young driver surcharge fee of $20 per day.
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand10 = !list.expand10">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand10 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How many drivers can be on the agreement?</span>
            </span>
          </b-row>
          <div v-show="list.expand10" class="answer">We offer 1 additional driver for free. Total maximum drivers are 2.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand11 = !list.expand11">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand11 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How do we arrange to get picked up once we arrive?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand11"
            class="answer"
          >Once you arrive, please call or text us. Our driver will come for you. It will only take 5-8 mins. We will send you text message with pick up vehicle details and waiting location.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand12 = !list.expand12">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand12 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I pick up a vehicle outside business hours?</span>
            </span>
          </b-row>
          <div v-show="list.expand12" class="answer">
            Yes, but you need let us to know at least 1 day in advance before pick up your car.
            <br />
            <br />
            <b>Note:</b>
            There will be a surcharge of $50 NZD for outside business hours service fee.
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand13 = !list.expand13">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand13 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How different is driving in New Zealand?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand13"
            class="answer"
          >Driving in New Zealand can be very different from your country. Our friendly staff are happy to provide safety driving instruction. We highly recommend you check the very useful information by clicking “Drive safe in New Zealand”</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand14 = !list.expand14">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand14 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do your rental cars all include unlimited miles?</span>
            </span>
          </b-row>
          <div v-show="list.expand14" class="answer">Yes, all cars have unlimited miles to use.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand15 = !list.expand15">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand15 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I smoke in the car?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand15"
            class="answer"
          >To ensure all our customer have a comfortable experience, we have ‘Smoke Free’ policy across all our rental vehicles. If you did not comply with the treaty, a minimum $400 NZD cleaning charge will be applied.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand16 = !list.expand16">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand16 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do you allow animals in your rental cars?</span>
            </span>
          </b-row>
          <div v-show="list.expand16" class="answer">No, Sorry, we are not allowed animals in the car.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand17 = !list.expand17">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand17 ? 'minus' : 'plus'"></i>
              <span>&nbsp; What do I do if I have an accident or breakdown?</span>
            </span>
          </b-row>
          <div v-show="list.expand17" class="answer">
            For accident, please call 111 for any emergency Fire, Police, and Ambulance.
            <br />For mechanical issues, please contact us on 0800 668 628
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand18 = !list.expand18">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand18 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Are there any roads I can’t drive on?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand18"
            class="answer"
          >You can drive almost any road except Ninety Mile Beach Road (Northland) and any roads north of Colville (Coromandel Peninsula).</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand19 = !list.expand19">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand19 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I take Interislander ferry to South Island?</span>
            </span>
          </b-row>
          <div v-show="list.expand19" class="answer">No, our vehicle is NOT allowed to drive in South Island. Please do not take ferry.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand20 = !list.expand20">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand20 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I extend my hire?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand20"
            class="answer"
          >If you’re looking to extend your hire, we strongly recommend that you contact us as soon as you can. Due to low availability in high season, we can’t guarantee any extension but we’ll do our best to accommodate your request.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand21 = !list.expand21">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand21 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How can I drop-off my car?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand21"
            class="answer"
          >Please drop off your car in our Auckland Depot at 40 Ascot Road, Mangere, Auckland. We will arrange free shuttle bus to transfer you to airport terminal.</div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand22 = !list.expand22">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand22 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drop-off a vehicle outside business hours?</span>
            </span>
          </b-row>
          <div v-show="list.expand22" class="answer">
            Yes, but you need let us to know at least 1 day in advance before drop off your car.
            <br />
            <br />
            <b>Note:</b> There will be a surcharge of $50 NZD for outside business hours service fee.
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand23 = !list.expand23">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand23 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drop-off a car in somewhere else in New Zealand?</span>
            </span>
          </b-row>
          <div v-show="list.expand23" class="answer">
            No, you must drop off your car in our Auckland Depot in 40 Ascot Road, Mangere, Auckland.
            <br />
            <br />
            <b>Note:</b>
            Do Not drop off the car in Airport carpark.
          </div>
        </li>
        <hr class="q-line" />

        <li>
          <b-row @click="list.expand24 = !list.expand24">
            <span class="ques-header fredoka h5">
              <i class="tt-icon soft-blue question h5" :class="list.expand24 ? 'minus' : 'plus'"></i>
              <span>&nbsp; If I return the car early, will I get a refund?</span>
            </span>
          </b-row>
          <div v-show="list.expand24" class="answer">Sorry, refund is not given for early returns. Please make your own travel schedule carefully.</div>
        </li>
        <hr class="q-line" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: {
                expand1: false,
                expand2: false,
                expand3: false,
                expand4: false,
                expand5: false,
                expand6: false,
                expand7: false,
                expand8: false,
                expand9: false,
                expand10: false,
                expand11: false,
                expand12: false,
                expand13: false,
                expand14: false,
                expand15: false,
                expand16: false,
                expand17: false,
                expand18: false,
                expand19: false,
                expand20: false,
                expand21: false,
                expand22: false,
                expand23: false,
                expand24: false,
            },
        };
    },
};
</script>

<style scoped>
div > ul {
    list-style-type: none;
}
ul ul {
    list-style-type: disc;
}
.ques-header {
    margin-left: 2vw;
    font-weight: 500;
}
.ques-header:hover {
    cursor: pointer;
    color: #2ea9df;
    background-color: #d6dbdd;
}
.answer {
    font-size: 1.2rem;
    margin: 0 3.8vw 1vw 3.8vw;
}
.line {
    width: 90%;
    color: gainsboro;
    margin-top: 3rem;
}
.q-line {
    width: 100%;
    color: gainsboro;
    margin: 0.3rem 0 0.3rem -1rem;
}
.tt-font {
    font-family: Georgia;
    font-size: 3.5rem;
}
.hot-title {
    text-align: center;
}
.hot-words {
    font-size: 1.5rem;
    font-family: Georgia;
    max-width: 85%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 4rem;
}
</style>
