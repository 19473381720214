<template>
  <div class="middle">
    <div class="m-0" v-if="$i18n.locale === 'en'">
      <h1 class="fredoka display-4 text-center mt-5">Terms & Conditions</h1>
      <p
        style="font-size:1.5rem"
        class="text-center mb-5"
      >This agreement is made between TT Group Limited (hereinafter referred to as the owner) and the hirer, whose particulars are recorded in this agreement, and is hereby agreed as follows:</p>
    </div>
    <div class="m-0" v-else>
      <h1 class="fredoka display-4 text-center mt-5">规则条例</h1>
      <p
        style="font-size:1.5rem"
        class="text-center mb-5"
      >This agreement is made between TT Group Limited (hereinafter referred to as the owner) and the hirer, whose particulars are recorded in this agreement, and is hereby agreed as follows:</p>
    </div>

    <div class="clauses px-3" v-if="$i18n.locale === 'en'">
      <h4>CLAUSE (1) VEHICLE DESCRIPTION</h4>
      <p>The owner will let and the hirer will take to hire the motor vehicle described on this agreement.</p>
      <h4>CLAUSE (2) DURATION OF HIRE</h4>
      <ol>
        <li>The hire term shall be as specified in this agreement.</li>
        <li>Any extension must be paid in full at the time of extension, and such extension shall form part of this agreement under the same terms and conditions as the original agreement. Written confirmation of the extension is required to avoid disputes.</li>
        <li>The owner reserves the right to repossess the vehicle if payment is overdue, with all repossession and recovery costs borne by the hirer.</li>
        <li>No refund will be issued for the early return of the vehicle before the agreed hire period ends.</li>
      </ol>
      <h4>CLAUSE (3) PERSON(S) WHO MAY DRIVE THE VEHICLE</h4>
      <ol>
        <li>The vehicle may only be driven during the hire period by persons expressly authorized in this agreement.</li>
        <li>Authorized drivers must hold a full, valid, and appropriate driver’s license at the time of driving. Details of the license must be recorded alongside their name in this agreement.</li>
      </ol>
      <h4>CLAUSE (4) PAYMENTS BY HIRER</h4>
      <ol>
        <li>The hirer must pay the Balance Due as specified in this agreement to the owner at the commencement of the hire.</li>
        <li>
          In addition to clause 4.1, the hirer must pay any applicable charges, including but not limited to:
          <ol>
            <li>Any excess fees for damage to the rental vehicle or third-party property.</li>
            <li>The cost of all fuel used during the hire period, including toll road fees.</li>
            <li>Additional charges as specified in Clause 12.</li>
            <li>Costs associated with vehicle damage, including but not limited to administration fees, loss of use fees, depreciation charges, roadside assistance fees, salvage and towing fees.</li>
          </ol>
        </li>
      </ol>
      <h4>CLAUSE (5) HIRER’S OBLIGATIONS</h4>
      <ol>
        <li>The hirer must take all reasonable care when handling and parking the vehicle.</li>
        <li>The vehicle must be securely locked when not in use.</li>
        <li>The hirer must maintain the vehicle in good condition, including keeping tyres properly inflated, oil and water levels sufficient, and ensuring roadworthiness at all times, in accordance with manufacturer guidelines and recommendations.</li>
      </ol>

      <h4>CLAUSE (6) RETURN OF THE VEHICLE</h4>
      <ol>
        <li>The hirer must return the vehicle to the owner’s or agent’s designated location at or before the agreed expiry of the hire term.</li>
        <li>The owner may terminate the agreement and repossess the vehicle if the hirer breaches any terms of this agreement or if the vehicle is damaged.</li>
        <li>Such termination does not prejudice the owner’s other rights under this agreement.</li>
      </ol>
      <h4>CLAUSE (7) OWNER’S OBLIGATIONS</h4>
      <ol>
        <li>The owner must provide the vehicle in a safe and roadworthy condition at the commencement of the hire.</li>
        <li>The owner is responsible for all regular and extraordinary costs associated with operating the vehicle during the hire period, except where such costs are explicitly assigned to the hirer under this agreement.</li>
      </ol>
      <h4>CLAUSE (8) MECHANICAL REPAIRS AND ACCIDENTS</h4>
      <ol>
        <li>If the vehicle is damaged or requires repair or salvage due to an accident or breakdown, the hirer must notify the owner immediately.</li>
        <li>Repairs or salvage must not be undertaken without the owner’s authorization, except to prevent further damage to the vehicle or other property.</li>
        <li>The hirer must not interfere with any mechanical components, including but not limited to the odometer, speedometer, engine, transmission, brakes, or suspension.</li>
        <li>Costs for damages to tyres, headlights, glass, the roof, and the undercarriage are the hirer’s responsibility, unless the hirer has purchased full cover insurance, in which case these damages can be waived.</li>
      </ol>
      <h4>CLAUSE (9) USE OF THE VEHICLE</h4>
      <ol>
        <li>The hirer must not:</li>
        <ol>
          <li>Use or allow the vehicle to be used to carry passengers for hire or reward. Examples of 'hire or reward' include, but are not limited to, ridesharing services, paid transportation of goods, or any other activity where payment is received for the use of the vehicle.</li>
          <li>Operate the vehicle, or allow it to be operated, in violation of Section 58 of the Transport Act 1962, which prohibits driving under the influence of alcohol or drugs.</li>
          <li>Use the vehicle under any of the exclusions specified in Clause 10.</li>
          <li>Use the vehicle to tow or propel any other vehicle.</li>
          <li>Operate the vehicle in breach of the Transport Act 1962, the Traffic Regulations 1976, or any other relevant laws, regulations, or bylaws relating to road traffic.</li>
          <li>Use the vehicle to transport animals, except where authorized (e.g., service animals).</li>
          <li>Use the vehicle for illegal purposes, including transporting contraband or committing any criminal activity.</li>
          <li>Drive the vehicle through hazardous conditions, such as beach, flooded areas, sand dunes, or snowfields, unless explicitly authorized by the owner.</li>
          <li>Drive the vehicle to Port Jackson Road, Coromandel Peninsula, Ninety Mile Beach in Northland.</li>
        </ol>
      </ol>
      <h4>CLAUSE (10) INSURANCE</h4>
      <ol>
        <li>The hirer and any authorized driver are indemnified for liability for damage to the rental vehicle, accessories, and third-party property under the insurance chosen in this agreement, and subject to the exclusions listed below.</li>
        <li>Exclusions include but are not limited to:</li>
        <ol>
          <li>Driving under the influence of alcohol or drugs.</li>
          <li>Operating the vehicle in races, rallies, or speed tests.</li>
          <li>Damage caused by improper use, off-road driving, water submersion, or crossing flooded areas.</li>
          <li>Interior damage due to burns, spills, or foreign materials.</li>
          <li>Negligence, including failure to heed warning lights, failure to maintain the vehicle in proper condition.</li>
          <li>Use outside the agreed hire period.</li>
          <li>Using a mobile phone while driving.</li>
          <li>Refuelling the vehicle with incorrect fuel.</li>
          <li>Driving the vehicle across islands (e.g., traveling to the South Island) without prior authorization.</li>
          <li>Impoundment of the vehicle by law enforcement due to violations committed by the hirer.</li>
        </ol>
      </ol>
      <h4>CLAUSE (11) REPLACEMENT OF THE VEHICLE</h4>
      <ol>
        <li>In the event of an accident, the hirer will not be entitled to any refund for unused rental days.</li>
        <li>The provision of a replacement vehicle, along with any associated insurance coverage, shall only be available in cases of mechanical failure. The owner shall not provide a replacement vehicle in situations involving other types of damage.</li>
      </ol>
      <h4>CLAUSE (12) ADDITIONAL CHARGES</h4>
      <ol>
        <li>Additional fee the owner will charge for below but not limited.</li>
        <ol>
          <li>$30 NZD service fee for fuel tank is not full on return.</li>
          <li>$30 NZD administrative fee will be charged for any infringements ofspeeding, parking, toll or breach notices.</li>
          <li>$125 NZD claims handling fee will be charged for managing vehicledamages in accidents.</li>
          <li>Breakdown or roadside assistance fees associated with salvage ortowing costs for non-mechanical breakdown unless full cover insurance isselected.</li>
          <li>Loss of use fee based on daily rental rates, up to a maximum of 7 days.</li>
        </ol>
      </ol>
    </div>
    <div class="clauses px-3" v-else></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.clauses > h4 {
    font-family: "Fredoka One", cursive;
}
.clauses > p,
.clauses > div {
    margin-left: 1.5rem;
}
.clauses > ol {
    margin-left: 0.5rem;
}
</style>
