<template>
  <div class="middle">
    <div v-if="$i18n.locale === 'en'">
      <div class="text-center mt-3">
        <h1 class="fredoka">Pick up your car</h1>
        <div>
          <p>Here you can find all the useful information about how to pick up your car.</p>
        </div>
      </div>

      <div class="mt-4">
        <h4 class="tips-heading">Important Information</h4>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="1" cols="2" class="border-bottom p-3">
              <b-card-img src="@/assets/pickup/driver-licence.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom">
              <div class="py-1">
                <h5>Driver’s Licence</h5>All drivers must carry the vaild full driver licence. Also an official English translation or IDP ( International Driver Permit) is required if not in English.
              </div>
            </b-col>

            <b-col md="1" cols="2" class="border-md-left border-bottom px-3 py-2">
              <b-card-img src="@/assets/pickup/credit-card.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom">
              <div class="py-1">
                <h5>Credit Card</h5>A valid Credit Card or Debit Card under driver's name with the Visa or Mastercard logo is required when collecting your car. $800 bond will be on hold. We do NOT accept CASH.
              </div>
            </b-col>

            <b-col md="1" cols="2" class="p-3 border-bottom border-md-bottom-0">
              <b-card-img src="@/assets/pickup/pickup.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom border-md-bottom-0">
              <div class="py-1">
                <h5>Pickup Process</h5>Please contact us once you landed and ready to go. Our shuttle bus will come to the terminal for picking up. We will inform you of the specific information about the shuttle bus and waiting location.
              </div>
            </b-col>

            <b-col md="1" cols="2" class="border-md-left p-3">
              <b-card-img src="@/assets/pickup/extension.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10">
              <div class="py-1">
                <h5>Extension</h5>If you're looking to extend your hire, we strongly recommend that you contact us as soon as possible. We'll do our best to accommodate your request however we can't guarantee any extension during hot season.
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand1 = !list.expand1">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand1 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How do I book a rental car with Top Rental?</span>
            </span>
          </b-row>
          <div v-show="list.expand1" class="answer">
            The easiest way to book is through our website
            <a href="/home">www.toprental.co.nz</a>;
            <br />You can also email us at
            <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a> or give us a call on:
            <br />
            <ul>
              <li>
                <a href="tel:0800 668 628">0800 668 628</a> From New Zealand
              </li>
              <li>
                <a href="tel:1800 861 966">1800 861 966</a> From Australia
              </li>
            </ul>
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand2 = !list.expand2">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand2 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Are Top Rental’s prices all inclusive?</span>
            </span>
          </b-row>
          <div v-show="list.expand2" class="answer">
            All shown prices are in NZD (New Zealand Dollars) and include 15% GST (Good and Service Tax), basic standard insurance liability cover.
            <br />
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand3 = !list.expand3">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand3 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How can I pay for my rental car?</span>
            </span>
          </b-row>
          <div v-show="list.expand3" class="answer">
            You will be asked to pay rental fee at counter before picked up your car. We accept all main multiple payment method which included Eftpos, Credit Card (VISA/MasterCard), Alipay, WeChat Pay, NZ Online Banking.
            <br />
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand4 = !list.expand4">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand4 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Do I have to pay a deposit/Bond when pick up?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand4"
            class="answer"
          >A valid Credit Card or Debit Card under driver's name with the Visa or Mastercard logo is required when collecting your car. $800 bond will be on hold. We do NOT accept CASH.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand5 = !list.expand5">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand5 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I use some else’s credit card?</span>
            </span>
          </b-row>
          <div v-show="list.expand5" class="answer">For security reason, we only accept driver’s credit card.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand6 = !list.expand6">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand6 ? 'minus' : 'plus'"></i>
              <span>&nbsp; What is your cancellation policy?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand6"
            class="answer"
          >Top Rental offer free cancellation policy. To cancel your booking, Just click “Mange your booking” then enter your details to finish your cancellation.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand7 = !list.expand7">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand7 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I request a certain make/model?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand7"
            class="answer"
          >We do the best to keep the certain model you requested. But however, we can’t promise as the vehicle stock may change quiet often.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand8 = !list.expand8">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand8 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I drive with my overseas licence?</span>
            </span>
          </b-row>
          <div v-show="list.expand8" class="answer">
            You need a valid overseas licence and a valid translation if not in English.
            <br />
            <br />
            <b>Note:</b> You must not be given a disqualification or suspension in New Zealand.
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand9 = !list.expand9">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand9 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Is there any age requirement for driver?</span>
            </span>
          </b-row>
          <div v-show="list.expand9" class="answer">
            You can drive our rental car if the age is over 21 years old. There is no maximum age to hire the car.
            <br />
            <br />
            <b>Note:</b>
            If the age is between 21 to 25 years old, it will has a young driver surcharge fee of $20 per day.
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand10 = !list.expand10">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand10 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How many drivers can be on the agreement?</span>
            </span>
          </b-row>
          <div v-show="list.expand10" class="answer">We offer 1 additional driver for free. Total maximum drivers are 2.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand11 = !list.expand11">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand11 ? 'minus' : 'plus'"></i>
              <span>&nbsp; How do we arrange to get picked up once we arrive?</span>
            </span>
          </b-row>
          <div
            v-show="list.expand11"
            class="answer"
          >Once you arrive, please call or text us. Our driver will come for you. It will only take 5-8 mins. We will send you text message with pick up vehicle details and waiting location.</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand12 = !list.expand12">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand12 ? 'minus' : 'plus'"></i>
              <span>&nbsp; Can I pick up a vehicle outside business hours?</span>
            </span>
          </b-row>
          <div v-show="list.expand12" class="answer">
            Yes, but you need let us to know at least 1 day in advance before pick up your car.
            <br />
            <br />
            <b>Note:</b>
            There will be a surcharge of $50 NZD for outside business hours service fee.
          </div>
        </div>
        <hr class="q-line" />
      </div>
    </div>

    <div v-else>
      <div class="text-center mt-3">
        <h1 class="fredoka">提车须知</h1>
        <div>
          <p>在这里您可以找到所有关于如何取车的信息和指引。</p>
        </div>
      </div>

      <div class="mt-4">
        <h4 class="tips-heading">重要信息</h4>
        <b-card no-body>
          <b-row no-gutters>
            <b-col md="1" cols="2" class="border-bottom p-3">
              <b-card-img src="@/assets/pickup/driver-licence.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom">
              <div class="py-1">
                <h5>驾照信息</h5>所有司机必须持有有效的正式驾驶执照。如果不是英文的，还需要官方的英文翻译或IDP(国际驾照)。
              </div>
            </b-col>

            <b-col md="1" cols="2" class="border-md-left border-bottom px-3 py-2">
              <b-card-img src="@/assets/pickup/credit-card.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom">
              <div class="py-1">
                <h5>信用卡</h5>在取车时，请出示司机名下有Visa或Mastercard标志的有效信用卡或借记卡。500新西兰元的预授权将被冻结。我们不接受现金。
              </div>
            </b-col>

            <b-col md="1" cols="2" class="p-3 border-bottom border-md-bottom-0">
              <b-card-img src="@/assets/pickup/pickup.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10" class="border-bottom border-md-bottom-0">
              <div class="py-1">
                <h5>取车流程</h5>您的航班落地并提取行李后，请与我们联系。我们的班车会在航站楼的接驳区等候，我们会通知您班车的具体信息和候车地点。
              </div>
            </b-col>

            <b-col md="1" cols="2" class="border-md-left p-3">
              <b-card-img src="@/assets/pickup/extension.png" alt="Image"></b-card-img>
            </b-col>
            <b-col md="5" cols="10">
              <div class="py-1">
                <h5>延期</h5>如果你想延长车辆租用期限，我们强烈建议您在确定行程后尽快与我们联系。我们会尽力满足您的要求，但是不能保证在旺季时间段延期。
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <div class="mt-5 mx-5">
        <div>
          <b-row @click="list.expand1 = !list.expand1">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand1 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我如何在Top Rental途行租车公司预订车辆?</span>
            </span>
          </b-row>
          <div v-show="list.expand1" class="answer">
            最方便快捷的方式是通过我们的网站
            <a href="/home">www.toprental.co.nz</a>;
            <br />您也可以发邮件给我们
            <a href="mailto:contact@toprental.co.nz">contact@toprental.co.nz</a> 或者拨打我们的免费热线：
            <br />
            <ul>
              <li>
                <a href="tel:0800 668 628">0800 668 628</a> 新西兰本地
              </li>
              <li>
                <a href="tel:1800 861 966">1800 861 966</a> 澳大利亚
              </li>
            </ul>
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand2 = !list.expand2">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand2 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我们的价格是否包税费？</span>
            </span>
          </b-row>
          <div v-show="list.expand2" class="answer">
            所有显示的价格都是新西兰元(NZD)，包括15%的GST(商品和服务税)，以及基础责任保险。
            <br />
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand3 = !list.expand3">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand3 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 有哪些付费方式？</span>
            </span>
          </b-row>
          <div v-show="list.expand3" class="answer">
            您将在取车前被要求在柜台支付租车费用。我们接受所有主要的多种支付方式，包括Eftpos，信用卡(VISA/MasterCard)，支付宝，微信支付，新西兰网上银行。
            <br />
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand4 = !list.expand4">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand4 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 提车时我需要付押金/保证金吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand4" class="answer">在取车时，请出示司机名下有Visa或Mastercard标志的有效信用卡或借记卡。500新西兰元的预授权会被冻结。我们不接受现金。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand5 = !list.expand5">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand5 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我能用别人的信用卡吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand5" class="answer">鉴于财产安全的考虑，我们只接受司机信用卡。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand6 = !list.expand6">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand6 ? 'minus' : 'plus'"></i>
              <span>&nbsp;你们的取消政策是什么?</span>
            </span>
          </b-row>
          <div v-show="list.expand6" class="answer">Top Rental提供免费取消政策。如需取消您的预订，只需点击“管理您的预订”，然后输入您的详细信息即可完成取消预订。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand7 = !list.expand7">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand7 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以指定一个特定的品牌/型号吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand7" class="answer">我们将尽力保留您所要求的特定型号。但是，我们不能保证，因为车辆库存时常会有变化。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand8 = !list.expand8">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand8 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以用我的海外驾照开车吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand8" class="answer">
            您需要一个有效的海外驾照和有效的翻译，如果不是英文的。
            <br />
            <br />
            <b>注意:</b>在新西兰驾驶期间，您的驾照不能被取消或暂扣。
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand9 = !list.expand9">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand9 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 对司机有年龄要求吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand9" class="answer">
            如果您的年龄超过21岁，可以放心使用我们的车辆。租用这辆车没有年龄限制。
            <br />
            <br />
            <b>注意:</b>
            如果年龄在21至25岁之间，每天将收取10新西兰元的年轻司机附加费。
          </div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand10 = !list.expand10">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand10 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 合同上可以有多少司机?</span>
            </span>
          </b-row>
          <div v-show="list.expand10" class="answer">我们可以免费添加一个额外的司机，最大驾驶员数为2位。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand11 = !list.expand11">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand11 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我们到达后如何安排接机?</span>
            </span>
          </b-row>
          <div v-show="list.expand11" class="answer">到达后，请给我们打电话或发短信。我们的司机会来接您，通常需要5-8分钟。我们会发短信通知您接车的详细信息和等候地点。</div>
        </div>
        <hr class="q-line" />

        <div>
          <b-row @click="list.expand12 = !list.expand12">
            <span class="ques-header">
              <i class="tt-icon soft-blue h4" :class="list.expand12 ? 'minus' : 'plus'"></i>
              <span>&nbsp; 我可以在营业时间以外取车吗?</span>
            </span>
          </b-row>
          <div v-show="list.expand12" class="answer">
            可以，但您需要在取车前至少提前1天通知我们。
            <br />
            <br />
            <b>注意:</b>
            营业时间以外的服务会产生额外费用$50 NZD。
          </div>
        </div>
        <hr class="q-line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: {
                expand1: false,
                expand2: false,
                expand3: false,
                expand4: false,
                expand5: false,
                expand6: false,
                expand7: false,
                expand8: false,
                expand9: false,
                expand10: false,
                expand11: false,
                expand12: false,
            },
        };
    },
};
</script>



<style scoped>
span > span {
    font-family: "Hind", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
}
</style>
