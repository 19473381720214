<template>
  <div class="bg-gradient-light">
    <div class="middle">
      <b-row class="p-2">
        <!-- progress -->

        <b-col class="col-md-9 col-12">
          <b-progress class="mt-5 bg-gradient-light" max="100" show-value height="0.5rem">
            <b-progress-bar value="25" variant="success">
              <h5 class="mb-0 position-absolute text-white bg-success px-2 rounded-circle">1</h5>
              <span class="text-dark position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Pickyourcar") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="warning">
              <h5 class="mb-0 position-absolute text-white bg-warning px-2 rounded-circle">2</h5>

              <span class="text-dark position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Insuranceextras") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="secondary">
              <h5 class="mb-0 position-absolute text-white bg-secondary px-2 rounded-circle">3</h5>

              <span class="text-black-50 position-absolute font-weight-bold h6" style="top: 1rem;">{{ $t("Driversdetails") }}</span>
            </b-progress-bar>
            <b-progress-bar value="25" variant="secondary">
              <h5 class="mb-0 position-absolute text-white bg-secondary px-2 rounded-circle">4</h5>
              <span class="text-black-50 position-absolute font-weight-bold h6 progress-font-top-bottom">{{ $t("Submit") }}</span>
            </b-progress-bar>
          </b-progress>

          <!-- Selected Vehicle -->

          <div class="mt-5 mt-sm-4">
            <h1 class="text-center">{{ $t("Vehicleyouchosen") }}</h1>
            <b-card class="col-12" no-body>
              <b-row>
                <b-overlay :show="!car.photo" rounded="sm" class="rounded col-sm-7 col-12 border-sm-right">
                  <b-img-lazy fluid :src="car.photo"></b-img-lazy>
                </b-overlay>
                <b-row class="col-12 col-sm-5" align-v="center" no-gutters>
                  <b-card-title class="text-dark col-12 border-bottom">
                    <b-row class="h1 text-info ml-1 car-title">{{ $t(removeBlank(car.model)) }}</b-row>
                    <b-row class="ml-1 font-weight-bold">{{ car.modelYear }}{{ $t("orsimilar") }}</b-row>
                  </b-card-title>
                  <b-row class="col-12">
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon passenger">{{ car.passengers }}</i>
                    </b-col>
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon cardoor">{{ car.doors }}</i>
                    </b-col>
                    <b-col class="col-4 col-sm-6 col-lg-3">
                      <i class="tt-icon luggage">{{ car.luggage }}</i>
                    </b-col>
                    <b-col class="col-5 col-sm-6 col-lg-3">
                      <i class="tt-icon engine">{{ car.engine }}</i>
                    </b-col>
                    <b-col auto>
                      <i class="tt-icon transmission">{{ $t(removeBlank(car.transmission)) }}</i>
                    </b-col>
                  </b-row>
                  <b-row class="border-top col-12 ml-0" align-v="center">
                    <b-row class="h1 col-12" align-v="center">
                      ${{ order.carAvgPrice }}
                      <span class="h3 mb-0">{{ $t("Day") }}</span>
                    </b-row>
                    <b-row class="h5 col-12 mt-1" align-v="center">
                      <b-col class="col-sm-12 p-0 col-md-6 col-6">
                        {{ $t("For") }}
                        {{ order.totalDay }}
                        {{ $t("Days") }}
                      </b-col>

                      <b-col class="col-sm-12 col-md-6 col-6">NZD ${{ order.carTotalMoney | moneyLocaelString }}</b-col>
                    </b-row>
                  </b-row>
                </b-row>
              </b-row>
            </b-card>
          </div>

          <!-- insurance -->

          <h1 class="text-center mt-3" ref="insuranceTitle">{{ $t("SelectYourInsurance") }}</h1>
          <b-card class="mt-3" no-body :class="[insuranceSelected ? '' : continueBtnClicked ? 'border-3 border-danger' : '']">
            <b-row class="py-3 mx-1" :class="idx !== 0 ? 'border-top' : ''" align-v="center" v-for="(ins, idx) in insuranceList" :key="ins.name">
              <b-col cols="5" sm="4" class="h4 text-info">{{ $t(ins.name) }}</b-col>
              <b-col cols="7" sm="4">{{ $i18n.locale === 'en' ? ins.desc : ins.desc_zh }}</b-col>
              <div class="w-100 mt-2 d-sm-none"></div>
              <!-- 分隔 -->
              <b-col cols="5" sm="2" class="text-sm-center">
                <span v-if="!ins.price">{{ $t("Free") }}</span>
                <span v-else>${{ ins.price }} {{ $t("Day") }}</span>
              </b-col>
              <b-col cols="7" sm="2" class="text-center text-sm-right">
                <b-button :variant="ins.selected ? 'success' : 'info'" @click="selectIns(ins)" :disabled="ins.selected">{{ $t("Select") }}</b-button>
              </b-col>
            </b-row>

            <!-- compare -->
            <b-table
              responsive
              class="border-top mb-0"
              striped
              :hover="false"
              :fields="$i18n.locale === 'en' ? insuranceFields : insuranceFields_zh"
              :items="$i18n.locale === 'en' ? insuranceItems : insuranceItems_zh"
              thead-class="bg-light text-info text-center"
              tbody-tr-class="text-center"
              style="font-size:0.8rem;"
            >
              <template v-slot:cell(item)="data">
                <span class="text-left d-block pl-sm-2">{{ data.value }}</span>
              </template>
              <template v-slot:head(item)>
                <span class="text-left d-block pl-sm-2">{{ $t("InsuranceComparison") }}</span>
              </template>
            </b-table>
          </b-card>
          <div class="font-weight-bold">{{ $t("Pleasenote") }}</div>

          <!-- options -->

          <h1 class="text-center mt-3">{{ $t("Extraoptions") }}</h1>

          <div class="d-flex flex-row flex-wrap mt-3">
            <b-card class="col-lg-6 col-12 options-card" v-for="item in itemsList" :key="item.name">
              <b-row no-gutters align-v="center">
                <b-col sm="4" cols="6">
                  <b-card-img :src="item.picUrl" alt="Image" class="rounded-0"></b-card-img>
                </b-col>
                <b-col sm="8" cols="6">
                  <b-card-body :title=" $i18n.locale === 'en' ?  item.name : item.name_zh">
                    <b-card-text>{{ $i18n.locale === 'en' ? item.desc : item.desc_zh }}</b-card-text>
                    <b-row no-gutters :align-h="item.byPices ? 'between' : 'end'" align-v="center">
                      <span class="h3 col-12 col-sm-6 col-md-6 col-lg-12 col-xl-7 text-center">
                        <span v-if="!!item.price">$ {{ item.price }} {{ $t("each") }}</span>
                        <span v-else>{{ $t("Free") }}</span>
                      </span>
                      <b-col class="text-center col-12 col-sm-6 col-md-6 col-lg-12 col-xl-5" v-if="!!item.price">
                        <b-form-spinbutton inline v-model="item.qty" min="0" :max="item.maxQty" @input="choosePics(item)"></b-form-spinbutton>
                      </b-col>

                      <b-col v-else class="text-right col-12 col-sm-6 col-md-6 col-lg-12 col-xl-5">
                        <b-button :variant="item.selected ? 'warning' : 'info'" class="col-12" @click="chooseOption(item)">{{ $t("Select") }}</b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>

        <!-- your quote -->
        <b-col class="col-md-3 col-12 mt-4">
          <div class="sticky-top">
            <b-form class="bg-white">
              <h3 class="bg-warning text-white p-3 rounded" v-if="!sellout">{{ $t("BookingSummary") }}</h3>
              <h3 class="bg-danger text-white p-3 rounded" v-else>{{ $t("Soldout") }}</h3>

              <div class="px-3">
                <div :class="[!!dateRangeError(order) ? '' : continueBtnClicked ? 'border border-3 border-danger p-1 rounded' : '']">
                  <b-row ref="datetimeRef" no-gutters>
                    <b-col cols="3">
                      <i class="tt-icon cheyaochi h2"></i>
                    </b-col>
                    <b-col cols="9 ml-n2">
                      <b-row no-gutters>
                        <h5 class="text-info mb-1">{{ $t("PickUp") }}</h5>
                        <i v-if="!changeDateStatus" @click="changeDateStatus = true" class="d-inline ml-auto tt-icon edit text-dark"></i>
                        <i v-else @click="changeDateStatus = false;" class="d-inline ml-auto tt-icon finish text-danger"></i>
                      </b-row>
                      <div>{{ order.pickUpLocation }}</div>
                      <div v-if="!changeDateStatus" class="font-weight-bold">{{ `${order.pDate + ' ' + order.pTime}` | dateTimeFormat }}</div>
                      <div v-else>
                        <b-form-datepicker
                          reset-button
                          close-button
                          size="sm"
                          class
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          v-model="order.pDate"
                          :min="tomorrowDay"
                          locale="en-GB"
                          placeholder="Select date"
                          @input="getCarTotalMoney(car.id)"
                        ></b-form-datepicker>
                        <b-form-timepicker
                          class
                          hide-header
                          minutes-step="30"
                          :hour12="false"
                          locale="en-GB"
                          reset-button
                          size="sm"
                          placeholder="Select time"
                          :show-seconds="false"
                          @context="onContext($event, 'pTime')"
                          @input="getCarTotalMoney(car.id)"
                          reset-value="12:00"
                          v-model="order.pTime"
                        ></b-form-timepicker>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row no-gutters class="mt-3">
                    <b-col cols="3" class>
                      <i class="tt-icon chefanhui h2"></i>
                    </b-col>
                    <b-col cols="9  ml-n2">
                      <h5 class="text-info mb-1">{{ $t("Return") }}</h5>
                      <div>{{ order.dropOffLocation }}</div>
                      <div v-if="!changeDateStatus" class="font-weight-bold">{{ `${order.rDate + ' ' +order.rTime}` | dateTimeFormat }}</div>
                      <div v-else>
                        <b-form-datepicker
                          reset-button
                          close-button
                          size="sm"
                          class
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          v-model="order.rDate"
                          :min="order.pDate"
                          locale="en-GB"
                          placeholder="Select date"
                          @input="getCarTotalMoney(car.id)"
                        ></b-form-datepicker>
                        <b-form-timepicker
                          class
                          hide-header
                          minutes-step="30"
                          :hour12="false"
                          locale="en-GB"
                          reset-button
                          size="sm"
                          placeholder="Select time"
                          :show-seconds="false"
                          @context="onContext($event, 'rTime')"
                          @input="getCarTotalMoney(car.id)"
                          reset-value="12:00"
                          v-model="order.rTime"
                        ></b-form-timepicker>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- <b-row no-gutters class="mt-1 text-danger" align-h="end" v-if="totalHoursShort">Minimum 2 days!</b-row> -->
                  <b-row no-gutters class="mt-3">
                    <b-col cols="3">
                      <i class="tt-icon ic_promo_codes h2"></i>
                    </b-col>
                    <b-col cols="9 ml-n2">
                      <h5 class="text-info">{{ $t("Promocode") }}</h5>
                      <b-input-group>
                        <b-form-input v-model="order.promoCode"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="success" @click="countSumPrice">{{ $t("Apply") }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <div class="border-top my-4"></div>

                <b-row>
                  <b-col cols="7">
                    <!-- {{ order.model }} -->
                    {{ $t("CarRentalFee") }}
                  </b-col>
                  <b-col cols="5" class="font-weight-bold text-right">&nbsp; ${{ order.carTotalMoney | moneyLocaelString }}</b-col>
                </b-row>
                <b-row v-for="(option, idx) in order.options" :key="idx">
                  <b-col cols="7">
                    {{ $t(option.item.replaceAll(" ","")) }}
                    <span v-if="!!option.qty && option.qty>1">&nbsp;x{{option.qty}}</span>
                  </b-col>
                  <b-col cols="5" class="font-weight-bold text-right">+ ${{ option.price | moneyLocaelString }}</b-col>
                </b-row>
                <b-row align-v="center" v-if="!!order.discount && order.discount > 0">
                  <b-col cols="7">{{ $t("Discount") }}</b-col>
                  <b-col cols="5" class="font-weight-bold text-danger text-right">- ${{ order.discount | moneyLocaelString }}</b-col>
                </b-row>

                <div class="border-top my-4"></div>
                <b-row align-v="center" no-gutters>
                  <b-col class="text-dark h3">{{ $t("TOTAL") }}</b-col>
                  <b-col auto class="h3 font-weight-bold text-right">
                    ${{ (order.totalPay) | moneyLocaelString}}
                    <br />NZD
                  </b-col>
                </b-row>

                <b-row class="pb-2">
                  <b-col cols="auto" class="ml-auto">{{ $t("For") }} {{ order.totalDay }} {{ $t("DaysGSTincluded") }}</b-col>
                </b-row>
              </div>
            </b-form>
            <b-form class="bg-white mt-5">
              <b-row align-h="center">
                <i class="tt-icon safe-payment text-success h1 mt-2"></i>
              </b-row>
              <h2 class="text-center">{{ $t("Howtopay") }}</h2>
              <div class="px-4 pt-2" v-if="$i18n.locale === 'en'">
                No pre-payment needed when booking.
                <br />
                <br />Credit card/debit card must be in the name of driver.
                <br />
                <br />
                <b>Card only show "Cardholder" will NOT accept.</b>
                <br />
                <br />We
                <b>only</b> accept card with Visa/Mastercard logo.
              </div>

              <div class="px-4 pt-2" v-else>
                预订时无需预先付款。
                <br />
                <br />信用卡/借记卡必须以司机实名登记。
                <br />
                <br />我们只接受带有Visa/Mastercard标识的卡。
              </div>
              <b-row align-h="between" class="px-4 py-2" no-gutters>
                <b-img fluid src="@/assets/other/visa.png"></b-img>
                <b-img fluid src="@/assets/other/mastercard.png"></b-img>
                <b-img fluid src="@/assets/other/wechat-pay.png"></b-img>
                <b-img fluid src="@/assets/other/alipay.png"></b-img>
              </b-row>
            </b-form>
          </div>
        </b-col>

        <b-row no-gutters style="height: 4rem;" class="col-md-9 col-12 d-flex flex-row justify-content-between my-4">
          <b-button size="lg" @click="back">
            <b-row class="px-2" align-v="center">
              <b-col>
                <span class="d-inline-block" style="transform: rotate(180deg);">➔</span>
                {{ $t("Back") }}
              </b-col>
            </b-row>
          </b-button>
          <b-button v-if="!sellout" size="lg" variant="success" @click="goDetails" :disabled="!!dateRangeError(order)">{{ $t("Continue") }} ➔</b-button>
          <b-button v-else size="lg" variant="danger" @click="back">
            {{ $t("Soldout") }}
            <span class="d-inline-block" style="transform: rotate(180deg);">➔</span>
          </b-button>
        </b-row>
      </b-row>
    </div>
  </div>
</template>

<script>
import utility from "../utils/utility";

export default {
    data() {
        let tomorrowDay = this.$dayjs(new Date()).add(24, "hour").format("YYYY-MM-DD");
        return {
            tomorrowDay: tomorrowDay,
            continueBtnClicked: false,
            car: {
                photo: "",
            },
            changeDateStatus: false,
            selloutCars: [],
            order: {
                // model: "Corolla",
                // carPrice: "20",
                // totalDay: "8",
                // pickUpTime: "2020-07-25 10:00",
                // returnTime: "2020-08-01 10:00",
                // totalPrice: "160",
                // paidFee: "",
                // discount: "",
                // promoCode: "",
                // options: [
                //   {
                //     category: "insurance",
                //     item: "Basic",
                //     qty: "1",s
                //     price: "0",
                //     discount: "0",
                //   },
                // ],
            },
            insuranceFields: [
                { key: "item", label: "" },
                { key: "basic", label: "Basic Cover" },
                { key: "plus", label: "Limited Cover" },
                { key: "premium", label: "Full Cover*" },
            ],
            insuranceFields_zh: [
                { key: "item", label: "" },
                { key: "basic", label: "基本险" },
                { key: "plus", label: "偷盗险" },
                { key: "premium", label: "全险*" },
            ],
            insuranceItems: [
                {
                    item: "Rental Vehicle Damage Excess Fee",
                    basic: "$4,800 NZD",
                    plus: "$800 NZD",
                    premium: "$0 NZD",
                },
                {
                    item: "Pre-Authorization on hold (as bond)",
                    basic: "$500 NZD",
                    plus: "$300 NZD",
                    premium: "$100 NZD",
                },
                {
                    item: "Your Vehicle Theft Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "AA Roadside Assistance Callout Fee",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Window Screen & Glass Cover",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "Tyres Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
                {
                    item: "Third Party Vehicle /  Property Damage Cover",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
            ],
            insuranceItems_zh: [
                {
                    item: "事故垫底费",
                    basic: "$4,800 NZD",
                    plus: "$800 NZD",
                    premium: "$0 NZD",
                },
                {
                    item: "预授权（押金）",
                    basic: "$500 NZD",
                    plus: "$300 NZD",
                    premium: "$100 NZD",
                },
                {
                    item: "车辆被盗",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "AA道路救援",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "玻璃险",
                    basic: "❌",
                    plus: "✔",
                    premium: "✔",
                },
                {
                    item: "轮胎",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
                {
                    item: "第三方车辆/财产",
                    basic: "❌",
                    plus: "❌",
                    premium: "✔",
                },
            ],
            insuranceList: [
                {
                    name: "Basic",
                    name_zh: "基本险",
                    desc: "Your Rental Vehicle Damage Excess Fee of $4,800 NZD",
                    desc_zh: "您的车辆事故垫底费是$4,800新西兰元",
                    price: 0,
                    discount: 0,
                    selected: false,
                },
                {
                    name: "Limited",
                    name_zh: "偷盗险",
                    desc: "Your Rental Vehicle Damage Excess Fee Reduced to $800 NZD",
                    desc_zh: "您的租赁车辆损坏时垫底费减少到$800新西兰元",
                    price: 32.0,
                    discount: 0,
                    selected: false,
                },
                {
                    name: "Full",
                    name_zh: "全险",
                    desc: "Your Rental Vehicle Damage Excess Fee Down to $0 NZD",
                    desc_zh: "您的租赁车辆损坏时垫底费降至$0新西兰元",
                    price: 39.0,
                    discount: 0,
                    selected: false,
                },
            ],
            itemsList: [
                {
                    name: "Phone Holder",
                    name_zh: "手机支架",
                    desc: "Free phone holder is provided for your convinience.",
                    desc_zh: "我们为您提供免费的手机支架",
                    maxQty: 4,
                    qty: 0,
                    price: 0,
                    picUrl: "../../img/options/phone-holder.jpg",
                    byPices: false,
                    selected: false,
                },
                {
                    name: "GPS Navigation",
                    name_zh: "GPS导航",
                    desc: "Free GPS Navigation with the latest NZ maps. Multiple languages available.",
                    desc_zh: "免费GPS导航与最新的新西兰地图。提供多种语言。",
                    qty: 0,
                    price: 0,
                    picUrl: "../../img/options/gps.jpg",
                    byPices: false,
                    selected: false,
                },
                {
                    name: "Booster Seat",
                    name_zh: "增高垫",
                    desc: "$35 NZD each. Suitable for child age between 4-7 years. ",
                    desc_zh: "每个35新西兰元。适合4-7岁儿童。",
                    maxQty: 2,
                    qty: 0,
                    price: 35,
                    picUrl: "../../img/options/booster-seat.jpg",
                    byPices: false,
                    selected: false,
                },
                {
                    name: "Baby Seat",
                    name_zh: "婴儿座椅",
                    desc: "$35 NZD each. Suitable for child age between 4-7 years. Rear facing seat is also available as request.",
                    desc_zh: "每个35新西兰元。适合4-7岁儿童。也可根据要求提供后面向座椅。",
                    maxQty: 2,
                    qty: 0,
                    price: 35,
                    picUrl: "../../img/options/baby-seat.jpg",
                    byPices: false,
                    selected: false,
                },
            ],
        };
    },
    computed: {
        insuranceSelected() {
            return !!this.order.options && this.order.options.some(o => o.category === "insurance");
            // return true;
        },
        pickUpTime() {
            let { pDate, pTime } = this.order;
            if (!pDate || !pTime) {
                return "";
            }
            return pDate + " " + pTime;
        },
        dropOffTime() {
            let { rDate, rTime } = this.order;
            if (!rDate || !rTime) {
                return "";
            }
            return rDate + " " + rTime;
        },
        sellout() {
            return this.selloutCars.indexOf(this.car.id) > -1;
        },
    },
    methods: {
        ...utility,
        onContext({ hours, minutes }, field) {
            if (hours === null) {
                this.order[field] = "12:00";
            }
            if (hours < 9) {
                this.order[field] = "09:00";
            } else if (hours > 18 || (hours === 18 && minutes !== 0)) {
                this.order[field] = "18:00";
            }
        },
        selectIns({ name, price, discount }) {
            this.insuranceList.forEach(o => (o.selected = o.name === name));
            let newIns = {
                category: "insurance",
                item: name,
                qty: 1,
                price: price,
                discount: discount,
            };
            this.spliceOption("insurance", newIns);
        },
        chooseOption(item) {
            item.selected = !item.selected;
            let { name, price, qty } = item;
            if (item.selected) {
                item.qty = 1;
                let newItem = {
                    category: name,
                    item: name,
                    qty: 1,
                    price: price * qty,
                };
                this.spliceOption(name, newItem);
            } else {
                item.qty = 0;
                this.spliceOption(name);
            }
        },
        choosePics(item) {
            let { name, price, qty } = item;
            if (qty > 0) {
                // let totalDay = Math.min(this.order.totalDay, 6);
                let newItem = {
                    category: name,
                    item: name,
                    qty: qty,
                    price: price * qty,
                };
                this.spliceOption(name, newItem);
            } else {
                this.spliceOption(name);
            }
        },
        spliceOption(catName, newItem = null) {
            let insIdx = -1;
            let has = this.order.options.some((opt, idx) => {
                if (opt.category === catName) {
                    insIdx = idx;
                    return true;
                }
            });
            if (!has) {
                insIdx = this.order.options.length;
            }
            let delCount = insIdx > -1 ? 1 : 0;
            if (!!newItem) {
                this.order.options.splice(insIdx, delCount, newItem);
            } else {
                this.order.options.splice(insIdx, delCount);
            }
            this.countSumPrice();
        },
        countSumPrice() {
            let { carTotalMoney, rDate, rTime, pDate, pTime } = this.order;
            let totalDay = Math.ceil(this.$dayjs(rDate + " " + rTime).diff(this.$dayjs(pDate + " " + pTime), "hour") / 24);
            let seatMaxDay = Math.min(totalDay, 6);
            this.order.options.forEach(o => {
                if (o.category === "Baby Seat" || o.category === "Booster Seat") {
                    let seat = this.itemsList.filter(item => item.name === o.category)[0];
                    let { price, qty } = seat;

                    let newPrice = price * qty;
                    this.$set(o, "price", newPrice);
                } else if (o.category === "insurance") {
                    let insurance = this.insuranceList.filter(item => item.name === o.item)[0];
                    let { price } = insurance;
                    let insSumPrice = price * totalDay;
                    this.$set(o, "price", insSumPrice);
                }
            });
            let optionsPriceSum = this.order.options.map(o => o.price).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            this.order.totalDay = totalDay;
            this.$set(this.order, "discount", this.queryDiscount());
            this.order.totalPrice = parseFloat(carTotalMoney) + parseFloat(optionsPriceSum);
            this.order.totalPay = this.order.totalPrice - this.order.discount;
            sessionStorage.setItem(
                "order",
                JSON.stringify(
                    Object.assign(this.order, {
                        pickUpTime: this.pickUpTime,
                        dropOffTime: this.dropOffTime,
                    })
                )
            );
        },
        queryDiscount() {
            let { promoCode, totalDay, carTotalMoney } = this.order;
            if (!promoCode) {
                return 0;
            }
            let discount = 0;
            let tempPromoCode = (promoCode || "").toUpperCase();
            if (["NEW5", "WEEK10", "LONG15", "LOYAL20"].indexOf(tempPromoCode) === -1) {
                let invald = "Invalid code!";
                if (this.$i18n.locale === "zh") {
                    invald = "无效的优惠码！";
                }
                this.$bvModal.msgBoxOk(invald, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return 0;
            }
            if (tempPromoCode === "NEW5" && totalDay >= 3) {
                // NEW5
                discount = 0.05;
            } else if (tempPromoCode === "WEEK10" && totalDay >= 7) {
                // WEEK10
                discount = 0.1;
            } else if (tempPromoCode === "LONG15" && totalDay >= 28) {
                // LONG15
                discount = 0.15;
            } else if (tempPromoCode === "LOYAL20" && totalDay >= 3) {
                // LOYAL20
                discount = 0.2;
            } else {
                let invalidString = "Invalid promo code, please check hot deal page for the conditions.";
                if (this.$i18n.locale === "zh") {
                    invalidString = "无效的优惠码，请查看热门优惠页面了解优惠条件。";
                }
                this.$bvModal.msgBoxOk(invalidString, {
                    size: "lg",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return 0;
            }
            return parseFloat(discount * carTotalMoney).toFixed(2);
        },
        back() {
            this.$router.go(-1);
        },
        checkInsuranceSelect() {
            if (!this.insuranceSelected) {
                let ins = this.$refs.insuranceTitle;
                window.scrollTo(0, ins.clientHeight);
                return false;
            }
            return true;
        },
        checkDateTime() {
            let error = this.dateRangeError(this.order);
            if (!!error) {
                let datetimeRef = this.$refs.datetimeRef;
                window.scrollTo(0, datetimeRef.clientHeight);
                return false;
            }
            return true;
        },
        goDetails() {
            this.continueBtnClicked = true;
            if (!this.checkInsuranceSelect() || !this.checkDateTime()) {
                return;
            }
            sessionStorage.setItem(
                "order",
                JSON.stringify(
                    Object.assign(this.order, {
                        pickUpTime: this.pickUpTime,
                        dropOffTime: this.dropOffTime,
                    })
                )
            );
            this.$router.push("Details");
        },
        async getCarTotalMoney(carId) {
            let error = this.dateRangeError(this.order);
            if (error) {
                this.$bvModal.msgBoxOk(error, {
                    size: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-1",
                    hideHeaderClose: true,
                    centered: true,
                });
                return;
            }
            let dateObj = this.dateRangeForMoney(this.order);
            let res = await this.$post("/api/getBookingCarById", Object.assign(dateObj, { carId: carId }));
            this.findSellout();
            this.car = res.data;
            let { carAvgPrice, dateSum, carTotalMoney } = this.car;
            this.order = Object.assign(this.order, {
                model: this.car.model,
                carAvgPrice: carAvgPrice,
                totalDay: dateSum,
                carTotalMoney: carTotalMoney,
            });
            this.countSumPrice();
        },
        async findSellout() {
            if (!(await this.verifyInShutdown())) {
                let dateObj = this.deconstructDate(this.order);
                let res = await this.$post("/api/getSelloutCars", dateObj);
                this.selloutCars = res.data;
            }
        },
        async verifyInShutdown() {
            let dateObj = this.deconstructDate(this.order);
            let res = await this.$post("/api/verifyDateIsIn", dateObj);
            if (res.data) {
                this.selloutCars = [this.car.id];
                return true;
            } else {
                this.selloutCars = [];
            }
            return false;
        },
    },

    mounted() {
        window.scrollTo(0, 0);
        let order = sessionStorage.getItem("order");
        this.order = JSON.parse(order);
        console.log(this.$route);
        let { carId } = this.order;

        //init options select
        if (!!this.order.options) {
            this.order.options.forEach(o => {
                if (o.category === "insurance") {
                    this.insuranceList.forEach(p => {
                        p.selected = p.name === o.item;
                    });
                } else {
                    this.itemsList.forEach(p => {
                        if (p.name === o.item) {
                            p.qty = o.qty;
                            p.selected = p.qty > 0;
                        }
                    });
                }
            });
        }

        //init date
        if (!this.pickUpTime) {
            let tomorrowDay = this.$dayjs(new Date()).add(1, "day").format("YYYY-MM-DD");
            this.$set(this.order, "pDate", tomorrowDay);
            this.$set(this.order, "pTime", "12:00");
        }
        if (!this.dropOffTime) {
            let tomorrowDay = this.$dayjs(new Date()).add(4, "day").format("YYYY-MM-DD");
            this.$set(this.order, "rDate", tomorrowDay);
            this.$set(this.order, "rTime", "12:00");
        }
        this.$set(this.order, "options", this.order.options || []);
        this.getCarTotalMoney(carId);
    },
};
</script>

<style scoped>
.finish:hover:before,
.edit:hover:before {
    color: var(--info);
    cursor: pointer;
}

.progress-font-top-bottom {
    top: 1rem;
}
@media screen and (max-width: 568px) {
    .progress-font-top-bottom {
        top: 4rem;
    }
}

.border-3 {
    border-width: 3px !important;
}
@media screen and (min-width: 568px) {
    .options-card:nth-child(1) .card-text {
        height: 70px !important;
    }
}
</style>
