<template>
  <div class="middle">
    <div class="about mx-auto">
      <p class="fredoka h1">{{ $t("ShuttleBus") }}</p>
      <p>
        {{ $t("Weprovidefree") }}
        {{ $t("Calluson") }}
        <a href="tel:0800 668 628">0800 668 628</a>
        ({{ $t("NZ") }}) {{ $t("Or") }}
        <a href="tel:1800 861 966">1800 861 966</a>
        ({{ $t("AU") }}) {{ $t("Whenyouare") }}
      </p>
    </div>

    <div class="mx-2 mx-md-5">
      <b-img src="@/assets/shuttle/shuttle.jpg" fluid-grow alt="Shuttle Bus"></b-img>
    </div>

    <div class="mx-2 mt-5 pt-lg-4 mx-md-5">
      <h3 class="fredoka">{{ $t("ForAucklandInternational") }}</h3>
      <p class="font-shuttle">{{ $t("Pleaseexitthrough") }}</p>
      <b-row>
        <b-col cols="6">
          <b-img class="rounded hoverpointer" @click="showModal('inter21.jpg')" src="@/assets/shuttle/inter21.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6">
          <b-img class="rounded hoverpointer" @click="showModal('inter22.jpg')" src="@/assets/shuttle/inter22.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter23.jpg')" src="@/assets/shuttle/inter23.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter24.jpg')" src="@/assets/shuttle/inter24.jpg" fluid-grow></b-img>
        </b-col>

        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter25.jpg')" src="@/assets/shuttle/inter25.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter26.jpg')" src="@/assets/shuttle/inter26.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter27.jpg')" src="@/assets/shuttle/inter27.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" style="margin-top: 2vw;">
          <b-img class="rounded hoverpointer" @click="showModal('inter28.jpg')" src="@/assets/shuttle/inter28.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" class="mt-3">
          <b-img class="rounded hoverpointer" @click="showModal('inter29.jpg')" src="@/assets/shuttle/inter29.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6" class="mt-3">
          <b-img class="rounded hoverpointer" @click="showModal('inter210.jpg')" src="@/assets/shuttle/inter210.jpg" fluid-grow></b-img>
        </b-col>
      </b-row>

      <h3 class="fredoka mt-5">{{ $t("ForAucklandDomestic") }}</h3>
      <p class="font-shuttle">{{ $t("Pleasewaitoutside") }}</p>
      <b-row>
        <b-col cols="6">
          <b-img class="rounded hoverpointer" @click="showModal('shuttle-domestic.jpg')" src="@/assets/shuttle/shuttle-domestic.jpg" fluid-grow></b-img>
        </b-col>
        <b-col cols="6">
          <b-img class="rounded hoverpointer" @click="showModal('domestic.jpg')" style="max-height:90%" src="@/assets/shuttle/domestic.jpg" fluid-grow></b-img>
        </b-col>
      </b-row>

      <b-modal ref="modal" hide-footer hide-header size="xl">
        <div v-if="!!imgPath">
          <b-img :src="require('@/assets/shuttle/'+imgPath)" fluid-grow></b-img>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return { imgPath: "" };
    },
    methods: {
        showModal(imgPath) {
            this.imgPath = imgPath;
            this.$refs["modal"].show();
        },
        hideModal() {
            this.$refs["modal"].hide();
        },
    },
};
</script>

<style scoped>
.about {
    font-size: 1.2rem;
    width: 88%;
    text-align: center;
    margin-top: 2rem;
}
.font-shuttle {
    font-size: 1.2rem;
}
</style>